import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./styles/NoteList.css"; // Import the custom styles
import "./styles/buttons.css";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
