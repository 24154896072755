import { Node, mergeAttributes } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import ReflectionComponent from "./ReflectionComponent";

const Reflection = Node.create({
  name: "reflection",
  group: "block",
  content: "block*",
  selectable: true,
  isolating: true,

  parseHTML() {
    return [
      {
        tag: "reflection.reflection-node",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["reflection", mergeAttributes(HTMLAttributes, { class: "reflection-node" }), 0];
  },

  addCommands() {
    return {
      setReflect:
        () =>
        ({ commands }) => {
          return commands.wrapIn(this.name);
        },
      toggleReflect:
        () =>
        ({ commands }) => {
          return commands.toggleWrap(this.name);
        },
      unsetReflect:
        () =>
        ({ commands }) => {
          return commands.lift(this.name);
        },
    };
  },

  addOptions() {
    return {
      user: null,
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer((props) => (
      <ReflectionComponent {...props} userId={this.options.userId} />
    ));
  },
});

export default Reflection;
