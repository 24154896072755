import axios from "axios";
import { cleanWithNewLines } from "../utils/parsing";

const API_URL =
  process.env.NODE_ENV === "production"
    ? `${process.env.REACT_APP_API_URL}/review`
    : "http://localhost:3001/review";

export const getReviewsByUserId = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/get/${userId}`);

    return response.data;
  } catch (error) {
    console.error("Error fetching reviews:", error);
    return [];
  }
};

export const createReviewForUser = async (review, userId) => {
  try {
    const body = { review: review.toDatabaseFormat(), reviewId: review.id };
    const response = await axios.post(`${API_URL}/create/${userId}`, body);
  } catch (error) {
    console.error("Error creating review:", error);
  }
};

export const getPreviousWeekReview = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/previous/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error getting previous week review:", error);
    return null;
  }
};

const validateResponse = (response) => {
  return response && response.summary && response.themes;
};

export const getSummaryAndThemes = async (notes, userId, forceEarlyGenerate) => {
  try {
    let response = await axios.post(`${API_URL}/summary`, {
      notes: notes.map((note) => cleanWithNewLines(note.content)),
      userId: userId,
      forceEarlyGenerate: forceEarlyGenerate,
      attempts: 0,
    });

    let attempts = 1;
    while (!validateResponse(response.data) && attempts < 3) {
      response = await axios.post(`${API_URL}/summary`, {
        notes: notes.map((note) => cleanWithNewLines(note.content)),
        userId: userId,
        forceEarlyGenerate: forceEarlyGenerate,
        attempts: attempts,
      });
      attempts++;
    }

    if (!validateResponse(response.data)) {
      throw new Error("Failed to get valid response after 3 attempts");
    }

    return response.data;
  } catch (error) {
    console.error("Error fetching summary and themes:", error);
    throw error;
  }
};

export const getNotesToSelf = async (notes, userId, forceEarlyGenerate) => {
  try {
    const response = await axios.post(`${API_URL}/notes-to-self`, {
      notes: notes.map((note) => cleanWithNewLines(note.content)),
      userId: userId,
      forceEarlyGenerate: forceEarlyGenerate,
    });

    return response.data.notesToSelf;
  } catch (error) {
    console.error("Error fetching notes to self:", error);
    throw error;
  }
};
