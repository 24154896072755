import React from "react";
import "../styles/TiptapEditor.css";
import { NodeViewWrapper } from "@tiptap/react";
import { Sparkle, X, ThumbsUp, ThumbsDown } from "lucide-react";
import { trackEvent } from "../services/trackUserAuthService";

const ReflectionComponent = ({ node, deleteNode, userId }) => {
  const [feedbackGiven, setFeedbackGiven] = React.useState(null);

  const handleDeleteReflection = () => {
    trackEvent("Reflection Deleted", {
      reflectionText: node.textContent,
      distinct_id: userId,
    });

    deleteNode();
  };

  const handleFeedback = (feedbackType) => {
    setFeedbackGiven(feedbackType);
    trackEvent("Reflection Feedback", {
      reflectionText: node.textContent,
      distinct_id: userId,
      feedbackType: feedbackType,
    });
  };

  return (
    <NodeViewWrapper className="reflection-container">
      <div className="reflection-content" contenteditable="true">
        <Sparkle className="reflection-sparkle" />
        <span contentEditable={false}>{node.textContent}</span>
        <div className="reflection-actions" contentEditable={false}>
          <button
            className={`reflection-btn ${feedbackGiven === "positive" ? "feedback-positive" : ""}`}
            onClick={() => handleFeedback("positive")}
            title="Helpful"
          >
            <ThumbsUp size={16} />
          </button>
          <button
            className={`reflection-btn ${feedbackGiven === "negative" ? "feedback-negative" : ""}`}
            onClick={() => {
              handleFeedback("negative");
              handleDeleteReflection();
            }}
            title="Not helpful"
          >
            <ThumbsDown size={16} />
          </button>
          <button className="reflection-btn" onClick={handleDeleteReflection}>
            <X size={20} />
          </button>
        </div>
      </div>
    </NodeViewWrapper>
  );
};

export default ReflectionComponent;
