import React, { useEffect, useState } from "react";
import "../styles/Dashboard.css";
import { EMOTION_COLORS } from "../constants/emotionColors";
import { Sparkle, Loader2, ChevronsLeft, ChevronsRight, RotateCcw } from "lucide-react";
import { getPreviousWeekReview } from "../services/reviewService";
import { getShortDate, getLastSunday, getLastSaturday } from "../utils/dateUtils";
import useIsMobile from "../utils/useIsMobile";

function Dashboard({ notes, user, generateReview }) {
  const [isGeneratingReview, setIsGeneratingReview] = useState(false);
  const [review, setReview] = useState(null);
  const [isEarlyReview, setIsEarlyReview] = useState(false);
  const [message, setMessage] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const isMobile = useIsMobile();
  const [numGenerations, setNumGenerations] = useState(0);
  const [isRotating, setIsRotating] = useState(false);

  const generateReviewEarly = async () => {
    try {
      const earlyReview = await generateReview(true);
      if (earlyReview) {
        setReview(earlyReview);
        setIsEarlyReview(true);
        setNumGenerations(1);
      } else {
        setMessage(
          <div className="zero-state-big-container">
            <div className="zero-state-container">
              <Sparkle size={20} className="zero-state-icon" />
            </div>

            <p className="zero-state-message">
              Not enough entries to generate a reflection yet for this week
            </p>
            <p className="zero-state-submessage">Journal more to unlock pearls of insight</p>
          </div>
        );
      }
    } catch (error) {
      console.error("Error generating early review:", error);
      setMessage(
        <div className="zero-state-big-container">
          <div className="zero-state-container">
            <Sparkle size={20} className="zero-state-icon" />
          </div>
          <p className="zero-state-message">We're having trouble finding pearls of insight</p>
          <p className="zero-state-submessage">Please try again later</p>
        </div>
      );
    }
  };

  const generateReviewEarlyButton = (
    <button className="weekly-review-button" onClick={generateReviewEarly}>
      <Sparkle size={14} className="weekly-review-icon" />
      <span className="button-text">Get this week's reflection early</span>
    </button>
  ); // later add a thing that pops up when youre out of reflections: wait -- and you can --

  // this logic is kind of messy, but it works
  useEffect(() => {
    const fetchReview = async () => {
      const { review: previousWeekReview, numGenerations } = await getPreviousWeekReview(user.uid);
      if (previousWeekReview !== null) {
        // found existing review
        setReview(previousWeekReview);
        setNumGenerations(numGenerations);
        const lastSunday = getLastSunday();
        const lastSaturday = getLastSaturday();
        // console.log("previous week review last sunday", previousWeekReview.startDate);
        // console.log("previous week review last saturday", previousWeekReview.endDate);
        // console.log("last sunday", lastSunday);
        // console.log("last saturday", lastSaturday);

        setIsEarlyReview(
          lastSunday < new Date(previousWeekReview.startDate) &&
            lastSaturday < new Date(previousWeekReview.endDate)
        );
      } else {
        setIsGeneratingReview(true);
        try {
          const newReview = await generateReview(false);
          if (newReview) {
            setReview(newReview);
            setNumGenerations(1);
            setIsEarlyReview(false);
          } else {
            // this happens when there's no notes in the last week
            setMessage(
              <div className="zero-state-big-container">
                <div className="zero-state-container">
                  <Sparkle size={20} className="zero-state-icon" />
                </div>

                <p className="zero-state-message">
                  Not enough entries to generate a reflection yet
                </p>
                <p className="zero-state-submessage">Journal more to unlock pearls of insight</p>
                {generateReviewEarlyButton}
              </div>
            );
          }
        } catch (error) {
          // this happens when there's a backend error
          console.error("Error generating review:", error);
          setMessage(
            <div className="zero-state-big-container">
              <div className="zero-state-container">
                <Sparkle size={20} className="zero-state-icon" />
              </div>
              <p className="zero-state-message">We're having trouble finding pearls of insight</p>
              <p className="zero-state-submessage">Please try again later</p>
            </div>
          );
        }
        setIsGeneratingReview(false);
      }
    };

    if (user?.uid) {
      fetchReview();
    }
  }, [user]);

  useEffect(() => {
    if (review) {
      // Small delay to ensure DOM is ready
      setTimeout(() => setIsVisible(true), 100);
    }
  }, [review]);

  // Add this helper function inside the component
  const getTopEmotions = (emotionBreakdown, count = 3) => {
    return Object.entries(emotionBreakdown)
      .sort(([, a], [, b]) => b - a)
      .slice(0, count)
      .map(([emotion, ratio]) => ({
        emotion,
        percentage: Math.round(ratio * 100),
      }));
  };

  const handleCollapseClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <>
      <div className={`dashboard-container ${isCollapsed ? "collapsed" : ""}`}>
        <div className="dashboard-header">
          <h2 className="dashboard-title">My reflections</h2>
          {!isMobile && (
            <div className="icon-button-group">
              <button onClick={handleCollapseClick} className="icon-button">
                <ChevronsLeft size={16} />
              </button>
            </div>
          )}
        </div>
        {isGeneratingReview ? (
          <div className="zero-state-big-container">
            <div className="zero-state-container loading-container">
              <Loader2 size={20} className="zero-state-icon loading-spinner" />
            </div>
            <p className="zero-state-message">Loading weekly reflection</p>
            <p className="zero-state-submessage">This may take a moment</p>
          </div>
        ) : review ? (
          <div className={`stats-grid ${isVisible ? "animate-fade-in" : "opacity-0"}`}>
            <div className="other-stats-section">
              <div className="heading-1">
                {isEarlyReview ? "This week..." : "Last week..."}
                <span className="date-range">
                  {getShortDate(
                    new Date(
                      getLastSunday().getTime() + (isEarlyReview ? 7 : 0) * 24 * 60 * 60 * 1000
                    )
                  )}
                  {" - "}
                  {getShortDate(
                    new Date(
                      getLastSaturday().getTime() + (isEarlyReview ? 7 : 0) * 24 * 60 * 60 * 1000
                    )
                  )}
                </span>
              </div>
              <div
                className="emotion-summary-section"
                style={{
                  background: `
                linear-gradient(180deg, #fdfafb3d 10%, #fdfafb9f 30%, #fdfafbb4 100%),
                linear-gradient(90deg, 
                  ${
                    EMOTION_COLORS[
                      Object.entries(review.emotionBreakdown).sort(([, a], [, b]) => b - a)[0]?.[0]
                    ]?.background || "#04c4ff3a"
                  } 30%, 
                  ${
                    EMOTION_COLORS[
                      Object.entries(review.emotionBreakdown).sort(([, a], [, b]) => b - a)[1]?.[0]
                    ]?.background || "#0800f921"
                  } 60%, 
                  ${
                    EMOTION_COLORS[
                      Object.entries(review.emotionBreakdown).sort(([, a], [, b]) => b - a)[2]?.[0]
                    ]?.background || "#ffc40053"
                  } 100%
                )
              `,
                }}
              >
                <div className="emotion-summary-grid">
                  {review.emotionBreakdown &&
                    getTopEmotions(review.emotionBreakdown).map(({ emotion, percentage }) => (
                      <div
                        key={emotion}
                        className={`emotion-summary-container ${emotion.toLowerCase()}`}
                        style={{
                          color: EMOTION_COLORS[emotion]?.text || "#322E33",
                        }}
                      >
                        <p className="emotion-summary-number">{percentage}%</p>
                        <p className="emotion-summary-emotion">{emotion.toLowerCase()}</p>
                      </div>
                    ))}
                </div>
                <div className="week-description">
                  <p>{review.summary}</p>
                </div>
                <div className="descriptive-words">
                  {review.themes.map((theme, index) => (
                    <React.Fragment key={index}>
                      <p className="descriptive-word">{theme.toLowerCase()}</p>
                      {index < review.themes.length - 1 && <p className="descriptive-star">✦</p>}
                    </React.Fragment>
                  ))}
                </div>
                {/* <button className="weekly-review-button">
              <Sparkle size={14} className="weekly-review-icon" />
              <span className="button-text">Write my weekly review</span>
            </button> */}
              </div>
              <div className="moments-section">
                <div className="moments-number">{review.numNotes}</div>
                <div className="moments-description-container">
                  <div className="moments-description">Moments saved</div>
                  <div className="moments-description-time">in the last week</div>
                </div>
              </div>
            </div>
            {/*<div className="other-stats-section">



            {/* <div className="theme-sections">
              <div className="heading-1">Overarching themes</div>
              <div className="themes">
                <div className="theme-section">
                  <div className="heading-2">Continuous threads</div>
                  <div className="theme-items">
                    <div className="theme-item">Appreciation for mom & brother</div>
                    <div className="theme-item">Repeated friction with meeting classmates</div>
                  </div>
                </div>
                <div className="theme-section">
                  <div className="heading-2">New directions</div>
                  <div className="theme-items">
                    <div className="theme-item">Appreciation for mom & brother</div>
                    <div className="theme-item">Anxiety about adjusting to new job</div>
                    <div className="theme-item">Repeated friction with meeting classmates</div>
                  </div>
                </div>
              </div>
            </div> */}
            {review?.notesToSelf?.length > 0 && (
              <div className="theme-sections">
                <div className="heading-1">Notes to self</div>
                <div className="themes">
                  <div className="theme-section">
                    <div className="theme-items">
                      {review.notesToSelf.map((note, index) => (
                        <div key={index} className="theme-item">
                          {note}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="dashboard-spacer"></div>
              </div>
            )}
            {
              <button
                className="weekly-review-button"
                onClick={async () => {
                  setIsRotating(true);
                  const newReview = await generateReview(isEarlyReview);
                  if (newReview) {
                    setReview(newReview);
                    setNumGenerations(numGenerations + 1);
                  }
                  setTimeout(() => setIsRotating(false), 500);
                }}
                disabled={numGenerations >= 2}
                title="You can regenerate your weekly review once for a fresh perspective"
              >
                <RotateCcw
                  size={14}
                  className={`weekly-review-icon ${isRotating ? "rotating" : ""}`}
                />
                <span className="button-text">Regenerate my review</span>
              </button>
            }
            {!isEarlyReview && generateReviewEarlyButton}
          </div>
        ) : (
          <div className="no-review-message">{message}</div>
        )}
      </div>
      {isCollapsed && (
        <div className="expand-button-container">
          <button onClick={handleCollapseClick} className="icon-button">
            <ChevronsRight size={16} className="text-[#3838688C]" />
          </button>
        </div>
      )}
    </>
  );
}

export default Dashboard;
