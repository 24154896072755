export const isWithinWeek = (lastEdited) => {
  const lastEditedDate = new Date(lastEdited);
  const today = new Date();
  const oneWeekAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
  return lastEditedDate >= oneWeekAgo && lastEditedDate <= today;
};

export const isWithinMonth = (lastEdited) => {
  const lastEditedDate = new Date(lastEdited);
  const today = new Date();
  const oneMonthAgo = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
  return lastEditedDate >= oneMonthAgo && lastEditedDate <= today;
};

export const isWithinYear = (lastEdited) => {
  const lastEditedDate = new Date(lastEdited);
  const today = new Date();
  const oneYearAgo = new Date(today.getTime() - 365 * 24 * 60 * 60 * 1000);
  return lastEditedDate >= oneYearAgo && lastEditedDate <= today;
};

export const getShortDate = (lastEdited) => {
  return new Date(lastEdited).toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });
};

export const getLastSunday = () => {
  const today = new Date();
  const currentDay = today.getUTCDay();

  // Calculate last Sunday (start of previous week)
  const lastSunday = new Date(
    Date.UTC(
      today.getUTCFullYear(),
      today.getUTCMonth(),
      today.getUTCDate() - currentDay - 7,
      0,
      0,
      0,
      0
    )
  );
  return lastSunday;
  // Calculate last Saturday (end of previous week)
};

export const getLastSaturday = () => {
  const lastSaturday = new Date(
    Date.UTC(
      getLastSunday().getUTCFullYear(),
      getLastSunday().getUTCMonth(),
      getLastSunday().getUTCDate() + 6,
      23,
      59,
      59,
      999
    )
  );
  return lastSaturday;
};
