import React, { useState, useEffect, useRef } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import Sidebar from "./components/Sidebar";
import NoteList from "./components/NoteList";
import NoteEditor from "./components/NoteEditor";
import EmotionGraph from "./components/EmotionGraph";
import {
  createNoteForUserDb,
  getNotesByUserIdDb,
  deleteNoteDb,
  updateNoteDb,
} from "./services/notesService";
import Note from "./models/noteModel";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
import useIsMobile from "./utils/useIsMobile";
import MobileBar from "./components/MobileBar";
import SignIn from "./components/SignIn";
import generateReflection from "./services/reflectionService";
import { MoreVertical, MoreHorizontal } from "lucide-react";
import Dashboard from "./components/Dashboard";
import WeeklyReview from "./models/weeklyReviewModel";
import { getSummaryAndThemes, createReviewForUser, getNotesToSelf } from "./services/reviewService";
import { getLastSunday, getLastSaturday } from "./utils/dateUtils";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ForgotPassword from "./components/ForgotPassword";
import SignOutMenu from "./components/SignOutMenu";

// Initialize Firebase
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

function App() {
  // Create an instance of the mixpanel client

  const [notes, setNotes] = useState([]);
  const [selectedNoteId, setSelectedNoteId] = useState(null);
  const [user, setUser] = useState(null);
  const [isGeneratingReflection, setIsGeneratingReflection] = useState(false);
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const location = useLocation();
  const [isEmotionGraphOpen, setIsEmotionGraphOpen] = useState(false);

  const noteId = location.pathname.startsWith("/note/")
    ? location.pathname.split("/note/")[1]
    : null;

  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
      if (!user) {
        navigate("/auth");
      }
    });

    return () => unregisterAuthObserver();
  }, []);

  const updateNotesDb = async () => {
    if (user) {
      notes.forEach(async (note) => {
        await updateNoteDb(note.id, note, user.uid);
      });
    }
  };

  useEffect(() => {
    return () => {
      // unmounting app, make sure all notes are up to date in db
      updateNotesDb();
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // console.log("Unloading app, updating notes in db");
      // updateNotesDb();
      event.preventDefault();
      event.returnValue = ""; // This is required for some browsers to show the confirmation dialog
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const { pathname } = useLocation();

  const fetchNotes = async () => {
    if (user) {
      const savedNotes = await getNotesByUserIdDb(user.uid);
      if (savedNotes) {
        const parsedNotes = savedNotes.map(
          (note) =>
            new Note(
              note.id,
              note.title,
              note.content,
              note.userId,
              note.lastEdited,
              note.emotions,
              note.hidden
            )
        );
        setNotes(parsedNotes);

        if (noteId) {
          setSelectedNoteIdAndNavigate(noteId);
        } else if (!isMobile && pathname !== "/new") {
          const mostRecentNote = parsedNotes.sort(
            (a, b) => new Date(b.lastEdited) - new Date(a.lastEdited)
          )[0];
          setSelectedNoteIdAndNavigate(mostRecentNote?.id || null);
        }
      } else if (!isMobile) {
        handleAddNote();
      }

      if (pathname === "/new") {
        handleAddNote();
      }
    }
  };

  useEffect(() => {
    fetchNotes(pathname !== "/new");
  }, [user]);

  useEffect(() => {
    // remove empty notes that are not the selected note
    const nonEmptyNotes = notes.filter(
      (note) => note.content.length > 0 || note.title.length > 0 || note.id === selectedNoteId
    );
    const emptyNoteIds = notes
      .filter(
        (note) => note.content.length === 0 && note.title.length === 0 && note.id !== selectedNoteId
      )
      .map((note) => note.id);
    // delete empty notes from firestore
    for (const id of emptyNoteIds) {
      deleteNoteDb(id, user.uid);
    }
    setNotes(nonEmptyNotes);
  }, [selectedNoteId]);

  const handleAddNote = async () => {
    const newNote = new Note(Date.now(), "", "", user.uid, new Date().toISOString(), []);

    setNotes((prevNotes) => [...prevNotes, newNote]);
    await createNoteForUserDb(newNote, user.uid);
    setSelectedNoteIdAndNavigate(newNote.id);
  };

  const handleDeleteNote = (noteId) => {
    setNotes((prevNotes) => {
      const updatedNotes = prevNotes.filter((note) => note.id !== noteId);
      if (!isMobile) {
        if (updatedNotes.length === 0) {
          const newNote = new Note(Date.now(), "", "", user.uid, new Date().toISOString(), []);
          createNoteForUserDb(newNote, user.uid);
          setSelectedNoteIdAndNavigate(newNote.id);
          return [newNote];
        }
        if (selectedNoteId === noteId) {
          setSelectedNoteIdAndNavigate(updatedNotes[0]?.id || null);
        }
      }
      deleteNoteDb(noteId, user.uid);
      return updatedNotes;
    });
  };

  const handleUpdateNote = (noteToUpdate, updatedFields, updateLastEdited = true) => {
    // Update the note before updating the state
    const updatedNote = noteToUpdate.update(updatedFields, updateLastEdited);

    // Update the notes state with the updated note
    setNotes((prevNotes) =>
      prevNotes.map((note) => (note.id === noteToUpdate.id ? updatedNote : note))
    );

    return updatedNote;
  };

  const handleSignOut = () => {
    updateNotesDb();
    firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(null);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const selectedNote = notes.find((note) => note.id === selectedNoteId);

  const setSelectedNoteIdAndNavigate = (noteId) => {
    setSelectedNoteId(noteId);
    navigate(`/note/${noteId}`);
  };

  const tiptapEditorRef = useRef(null);

  const generateReflectionForSelectedNote = async () => {
    setIsGeneratingReflection(true);
    const reflection = await generateReflection(
      selectedNote.content,
      selectedNote.userId,
      selectedNote.id
    );
    if (reflection === "No question") {
      toast("Please provide more context in your journal entry for reflection", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setIsGeneratingReflection(false);
      return;
    }
    // console.log(reflection);
    // console.log(tiptapEditorRef.current);
    if (tiptapEditorRef.current && reflection !== "") {
      await tiptapEditorRef.current.insertReflection(reflection);
    }

    setIsGeneratingReflection(false);
  };

  const generateWeeklyReview = async (forceEarlyGenerate = false) => {
    // Calculate date range for the past week (Sunday to Saturday)
    // console.log("force early generate", forceEarlyGenerate);
    let lastSunday;
    let lastSaturday;
    if (!forceEarlyGenerate) {
      lastSunday = getLastSunday();
      lastSaturday = getLastSaturday();
    } else {
      // Add 7 days (7 * 24 * 60 * 60 * 1000 milliseconds)
      lastSunday = new Date(getLastSunday().getTime() + 7 * 24 * 60 * 60 * 1000);
      lastSaturday = new Date(getLastSaturday().getTime() + 7 * 24 * 60 * 60 * 1000);
    }

    // Filter notes from the past week
    const weeklyNotes = notes.filter((note) => {
      const noteDate = new Date(note.lastEdited);
      return noteDate >= lastSunday && noteDate <= lastSaturday;
    });

    if (weeklyNotes.length < 2) {
      return null;
    }
    // First count total emotions and individual emotion occurrences
    let totalEmotions = 0;
    const emotionCounts = {};

    weeklyNotes.forEach((note) => {
      note.emotions.forEach((emotion) => {
        emotionCounts[emotion] = (emotionCounts[emotion] || 0) + 1;
        totalEmotions++;
      });
    });

    // Convert counts to fractions
    const emotionBreakdown = {};
    Object.keys(emotionCounts).forEach((emotion) => {
      emotionBreakdown[emotion] = totalEmotions > 0 ? emotionCounts[emotion] / totalEmotions : 0;
    });

    const { summary, themes } = await getSummaryAndThemes(
      weeklyNotes,
      user.uid,
      forceEarlyGenerate
    );

    if (summary === "No summary") {
      return null;
    }
    const reviewId = crypto.randomUUID();
    // Create weekly review object

    const notesToSelf = await getNotesToSelf(weeklyNotes, user.uid, forceEarlyGenerate);

    const weeklyReview = new WeeklyReview(
      reviewId,
      emotionBreakdown,
      weeklyNotes.length,
      summary,
      themes,
      notesToSelf,
      [],
      [],
      lastSunday,
      lastSaturday,
      new Date()
    );

    // save in db
    await createReviewForUser(weeklyReview, user.uid);
    return weeklyReview;
  };

  const SidebarAndNoteList = () => {
    return (
      <>
        {isMobile ? (
          <MobileBar addNote={handleAddNote} />
        ) : (
          <Sidebar
            addNote={handleAddNote}
            deleteSelectedNote={handleDeleteNote}
            selectedNoteId={selectedNoteId}
            generateReflection={generateReflectionForSelectedNote}
            isGeneratingReflection={isGeneratingReflection}
            notes={notes}
            setSelectedNoteId={setSelectedNoteIdAndNavigate}
          />
        )}
        <NoteList
          notes={notes}
          selectedNote={selectedNote}
          setSelectedNoteId={setSelectedNoteIdAndNavigate}
          selectedNoteId={selectedNoteId}
          updateNote={handleUpdateNote}
          deleteSelectedNote={handleDeleteNote}
        />
      </>
    );
  };

  function NewNote() {
    useEffect(() => {
      // handleAddNote();
    }, []);

    return <SidebarAndNoteList />;
  }

  return (
    <>
      <ToastContainer />
      <div
        className="flex w-screen h-screen bg-[#FCF9FA]"
        data-route={location.pathname}
        data-emotion-graph={isEmotionGraphOpen ? "open" : "closed"}
      >
        <div className="route-container flex flex-row flex-auto">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <SignOutMenu onSignOut={handleSignOut} user={user} />
                  <SidebarAndNoteList />
                  {!isMobile && selectedNoteId && (
                    <NoteEditor
                      ref={tiptapEditorRef}
                      note={selectedNote}
                      updateNote={handleUpdateNote}
                      generateReflection={generateReflectionForSelectedNote}
                      isGeneratingReflection={isGeneratingReflection}
                    />
                  )}
                </>
              }
            />
            <Route
              path="/note/:noteId"
              element={
                <>
                  <SignOutMenu onSignOut={handleSignOut} user={user} />
                  {!isMobile && (
                    <>
                      <Sidebar
                        addNote={handleAddNote}
                        deleteSelectedNote={handleDeleteNote}
                        selectedNoteId={selectedNoteId}
                        generateReflection={generateReflectionForSelectedNote}
                        isGeneratingReflection={isGeneratingReflection}
                        notes={notes}
                        setSelectedNoteId={setSelectedNoteIdAndNavigate}
                      />
                      <NoteList
                        notes={notes}
                        selectedNote={selectedNote}
                        setSelectedNoteId={setSelectedNoteIdAndNavigate}
                        selectedNoteId={selectedNoteId}
                        updateNote={handleUpdateNote}
                        deleteSelectedNote={handleDeleteNote}
                      />
                    </>
                  )}
                  <NoteEditor
                    ref={tiptapEditorRef}
                    note={selectedNote}
                    updateNote={handleUpdateNote}
                    generateReflection={generateReflectionForSelectedNote}
                    isGeneratingReflection={isGeneratingReflection}
                  />
                </>
              }
            />
            <Route path="/new" element={<NewNote />} />
            <Route
              path="/graph"
              element={
                <>
                  <SignOutMenu onSignOut={handleSignOut} user={user} />
                  {!isMobile ? (
                    <>
                      <Sidebar
                        addNote={handleAddNote}
                        deleteSelectedNote={handleDeleteNote}
                        selectedNoteId={selectedNoteId}
                        generateReflection={generateReflectionForSelectedNote}
                        isGeneratingReflection={isGeneratingReflection}
                        notes={notes}
                        setSelectedNoteId={setSelectedNoteIdAndNavigate}
                      />
                      <Dashboard notes={notes} user={user} generateReview={generateWeeklyReview} />
                      <div className="emotion-graph-container flex-1">
                        <EmotionGraph
                          notes={notes}
                          setSelectedNoteId={setSelectedNoteIdAndNavigate}
                          setIsEmotionGraphOpen={setIsEmotionGraphOpen}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <MobileBar addNote={handleAddNote} />
                      <div className="flex flex-col">
                        <div className="emotion-graph-container flex-1">
                          <EmotionGraph
                            notes={notes}
                            setSelectedNoteId={setSelectedNoteIdAndNavigate}
                            setIsEmotionGraphOpen={setIsEmotionGraphOpen}
                          />
                        </div>
                        <Dashboard
                          notes={notes}
                          user={user}
                          generateReview={generateWeeklyReview}
                        />
                      </div>
                    </>
                  )}
                </>
              }
            />
            <Route path="/auth" element={<SignIn setUser={setUser} />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </Routes>
        </div>
      </div>
    </>
  );
}

export default App;
