const saveTimeouts = new Map();
const analyzeTimeouts = new Map();
const showPromptTimeouts = new Map();

export const setShowPromptTimeout = (noteId, callback, delay) => {
  if (showPromptTimeouts.has(noteId)) {
    clearTimeout(showPromptTimeouts.get(noteId));
  }
  const timeoutId = setTimeout(() => {
    callback();
    showPromptTimeouts.delete(noteId);
  }, delay);
  showPromptTimeouts.set(noteId, timeoutId);
};

export const setSaveTimeout = (noteId, callback, delay) => {
  if (saveTimeouts.has(noteId)) {
    clearTimeout(saveTimeouts.get(noteId));
  }
  const timeoutId = setTimeout(() => {
    callback();
    saveTimeouts.delete(noteId);
  }, delay);
  saveTimeouts.set(noteId, timeoutId);
};

export const setAnalyzeTimeout = (noteId, callback, delay) => {
  if (analyzeTimeouts.has(noteId)) {
    clearTimeout(analyzeTimeouts.get(noteId));
  }
  const timeoutId = setTimeout(() => {
    callback();
    analyzeTimeouts.delete(noteId);
  }, delay);
  analyzeTimeouts.set(noteId, timeoutId);
};

export const clearTimeoutsForNote = (noteId) => {
  if (saveTimeouts.has(noteId)) {
    clearTimeout(saveTimeouts.get(noteId));
    saveTimeouts.delete(noteId);
  }
  if (analyzeTimeouts.has(noteId)) {
    clearTimeout(analyzeTimeouts.get(noteId));
    analyzeTimeouts.delete(noteId);
  }
};
