class Note {
  constructor(id, title, content, userId, lastEdited, emotions, hidden = false) {
    this.id = id;
    this.title = title;
    this.userId = userId;
    this.content = content;
    this.lastEdited = lastEdited;
    this.emotions = emotions;
    this.hidden = hidden;
  }

  toDatabaseFormat() {
    return {
      title: this.title,
      userId: this.userId,
      content: this.content,
      lastEdited: this.lastEdited,
      emotions: this.emotions,
      hidden: this.hidden,
    };
  }
  // updatedFields: Partial<Note>, updateLastEdited: boolean = true
  update(updatedFields, updateLastEdited = true) {
    const newNote = new Note(
      this.id,
      updatedFields.title ?? this.title,
      updatedFields.content ?? this.content,
      this.userId,
      updateLastEdited ? new Date().toISOString() : this.lastEdited,
      updatedFields.emotions ?? this.emotions,
      updatedFields.hasOwnProperty("hidden") ? updatedFields.hidden : this.hidden
    );
    return newNote;
  }
}

export default Note;
