class WeeklyReview {
  constructor(
    id,
    emotionBreakdown,
    numNotes,
    summary,
    themes,
    notesToSelf,
    continuousThreads,
    newDirections,
    startDate,
    endDate,
    addedDate
  ) {
    this.id = id;
    this.emotionBreakdown = emotionBreakdown;
    this.numNotes = numNotes;
    this.summary = summary;
    this.themes = themes;
    this.notesToSelf = notesToSelf;
    this.continuousThreads = continuousThreads;
    this.newDirections = newDirections;
    this.startDate = startDate;
    this.endDate = endDate;
    this.addedDate = addedDate;
  }

  toDatabaseFormat() {
    return {
      id: this.id,
      emotionBreakdown: this.emotionBreakdown,
      numNotes: this.numNotes,
      summary: this.summary,
      themes: this.themes,
      notesToSelf: this.notesToSelf,
      continuousThreads: this.continuousThreads,
      newDirections: this.newDirections,
      startDate: this.startDate,
      endDate: this.endDate,
      addedDate: this.addedDate,
    };
  }
}

export default WeeklyReview;
