import axios from "axios";
import { cleanWithNewLines } from "../utils/parsing";
const API_URL =
  process.env.NODE_ENV === "production" ? process.env.REACT_APP_API_URL : "http://localhost:3001";

export const generateTitleFromContent = async (content, userId, noteId) => {
  try {
    const response = await axios.post(`${API_URL}/generate-title`, {
      content: cleanWithNewLines(content),
      userId: userId,
      noteId: noteId,
    });

    if (!response.data.title) {
      throw new Error("Failed to generate title");
    }

    return response.data.title;
  } catch (error) {
    console.error("Error generating title:", error);
    throw error;
  }
};
