import React, { useEffect, useRef } from "react";
import { BookHeart, Waypoints, FilePenLine, Trash2 } from "lucide-react";
import { Sparkle, Heart } from "lucide-react";
import useIsMobile from "../utils/useIsMobile";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import "../styles/Sidebar.css";

function Sidebar({
  addNote,
  generateReflection,
  isGeneratingReflection,
  notes,
  setSelectedNoteId,
  deleteSelectedNote,
  selectedNoteId,
  setIsEmotionGraphOpen,
}) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isMobile = useIsMobile();
  const prevIsMobile = useRef(isMobile);

  // Change this logic to check if we're in the graph view instead
  const isGraphView = pathname.includes("/graph");
  
  // If we're in desktop mode and have a selected note, we should show emotion graph button
  const isNotePath = isMobile ? pathname.includes("/note") : selectedNoteId !== null;

  useEffect(() => {
    if (prevIsMobile.current !== isMobile) {
      if (!isMobile && selectedNoteId) {
        setIsEmotionGraphOpen(false);
      }
      prevIsMobile.current = isMobile;
    }
  }, [isMobile, selectedNoteId]);

  const handleBookHeartClick = () => {
    if (isGraphView) {
      // If we're in graph view, go to notes view
      if (notes.length > 0) {
        const mostRecentNote = notes.sort(
          (a, b) => new Date(b.lastEdited) - new Date(a.lastEdited)
        )[0];
        setSelectedNoteId(mostRecentNote.id);
        navigate(`/note/${mostRecentNote.id}`);
      } else {
        navigate('/');
      }
    } else {
      // If we're in notes view, go to graph view
      navigate('/graph');
    }
  };

  return (
    <div className="sidebar-container">
      <div className="sidebar">
        <button onClick={handleBookHeartClick} className="icon-button">
          {isGraphView ? <BookHeart size={16} /> : <Waypoints size={16} />}
          <span className="tooltip tooltip-top-left">{isGraphView ? 'Notes' : 'Dashboard'}</span>
        </button>
        <div className="sidebar-divider" />
        {!isGraphView && (
          <>
            {isNotePath && (
              <button
                onClick={generateReflection}
                className="sidebar-button reflect-button"
                disabled={isGeneratingReflection}
              >
                {isGeneratingReflection ? (
                  <span>
                    <Heart
                      className="mb-[3px] animate-[pulse_1.25s_cubic-bezier(0.4,0,0.6,1)_infinite]"
                      size={12}
                    />
                  </span>
                ) : (
                  <Sparkle className="sparkle-sidebar-icon" />
                )}
                Reflect
              </button>
            )}
            {selectedNoteId && isNotePath && (
              <button
                onClick={() => deleteSelectedNote(selectedNoteId)}
                className="icon-button"
                disabled={!selectedNoteId}
              >
                <Trash2 size={16} />
                <span className="tooltip tooltip-top-right">Delete Note</span>
              </button>
            )}
          </>
        )}
        <button onClick={addNote} className="icon-button">
          <FilePenLine size={16} />
          <span className="tooltip tooltip-top-right">New Note</span>
        </button>
      </div>
    </div>
  );
}

export default Sidebar;
