import axios from "axios";

const API_URL =
  process.env.NODE_ENV === "production"
    ? `${process.env.REACT_APP_API_URL}/notes`
    : "http://localhost:3001/notes";

export const createNoteForUserDb = async (note, userId) => {
  try {
    const body = { note: note.toDatabaseFormat(), noteId: note.id };
    const response = await axios.post(`${API_URL}/create/${userId}`, body);
  } catch (error) {
    console.error("Error creating note:", error);
  }
};

export const getNoteByIdDb = async (id, userId) => {
  try {
    const response = await axios.get(`${API_URL}/get/${userId}/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching note:", error);
  }
};

export const updateNoteDb = async (id, note, userId) => {
  try {
    const body = { note: note.toDatabaseFormat() };
    const response = await axios.put(`${API_URL}/update/${userId}/${id}`, body);
  } catch (error) {
    console.error("Error updating note:", error);
  }
};

export const deleteNoteDb = async (id, userId) => {
  try {
    const response = await axios.delete(`${API_URL}/delete/${userId}/${id}`);
  } catch (error) {
    console.error("Error deleting note:", error);
  }
};

export const getNotesByUserIdDb = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/get/${userId}`);

    return response.data;
  } catch (error) {
    console.error("Error fetching notes:", error);
  }
};
