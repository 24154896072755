import React, { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useNavigate } from "react-router-dom";
import "../styles/SignIn.css";
import "../styles/buttons.css";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const cleanFirebaseError = (errorMessage) => {
    return errorMessage.replace("Firebase: ", "").replace(/\s*\([^)]*\)\.?/, "");
  };

  const handleResetPassword = () => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        setMessage("Password reset email sent!");
        setError("");
        // Redirect to signin after 3 seconds
        setTimeout(() => {
          navigate("/auth");
        }, 3000);
      })
      .catch((error) => {
        setError(cleanFirebaseError(error.message));
        setMessage("");
      });
  };

  return (
    <div className="sign-in-container">
      <h2 className="mb-4 sign-in-header">Reset Password</h2>

      <div className="sign-in-items">
        <input
          type="jinx@exampleemail.com"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="input-field-container"
        />

        <button onClick={handleResetPassword} className="signin-button">
          Send Reset Link
        </button>
      </div>

      <button onClick={() => navigate("/auth")} className="small-text">
        Back to Sign In
      </button>

      {error && <div className="text-red-500 text-center mt-4">{error}</div>}
      {message && <div className="text-green-500 text-center mt-4">{message}</div>}
    </div>
  );
}

export default ForgotPassword;
