import axios from "axios";
import { removeHTMLTagsAndReflections } from "../utils/parsing";
const API_URL =
  process.env.NODE_ENV === "production"
    ? `${process.env.REACT_APP_API_URL}/analyze-emotions`
    : "http://localhost:3001/analyze-emotions";

const HUME_API_URL =
  process.env.NODE_ENV === "production"
    ? `${process.env.REACT_APP_API_URL}/analyze-emotions-hume`
    : "http://localhost:3001/analyze-emotions-hume";

const analyzeEmotions = async (content) => {
  try {
    const plainText = removeHTMLTagsAndReflections(content);

    const response = await axios.post(API_URL, { content: plainText });
    return response.data.emotions;
  } catch (error) {
    console.error("Error analyzing emotions:", error);
    return [];
  }
};

const analyzeEmotionsHume = async (content, userId, noteId) => {
  try {
    const plainText = removeHTMLTagsAndReflections(content);
    const response = await axios.post(HUME_API_URL, {
      content: plainText,
      userId: userId,
      noteId: noteId,
    });
    const emotions = response.data.emotions;
    if (emotions.filter((emotion) => emotion.score > 0.3).length === 0) {
      return [emotions.sort((a, b) => b.score - a.score)[0].name]; // take the highest scoring emotion if no emotions are above 0.3
    }
    return emotions
      .filter((emotion) => emotion.score > 0.3)
      .sort((a, b) => b.score - a.score)
      .map((emotion) => emotion.name)
      .slice(0, 3); // Limit to top 3 emotions
  } catch (error) {
    console.error("Error analyzing emotions:", error);
    return [];
  }
};

export { analyzeEmotions, analyzeEmotionsHume };
