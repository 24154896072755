import axios from "axios";
import { extractReflections } from "../utils/parsing";
const API_URL =
  process.env.NODE_ENV === "production"
    ? "https://journal-server-two.vercel.app/reflect"
    : "http://localhost:3001/reflect";

const generateReflection = async (content, userId, noteId) => {
  try {
    const { cleanedText, reflections } = extractReflections(content);
    const response = await axios.post(API_URL, {
      content: cleanedText,
      reflections,
      userId,
      noteId,
    });
    return response.data.question;
  } catch (error) {
    console.error("Error generating reflection:", error);
    return "";
  }
};

export default generateReflection;
