import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import "../styles/EmotionGraph.css";
import useIsMobile from "../utils/useIsMobile";
import { EMOTION_COLORS } from "../constants/emotionColors";
import { cleanWithNewLines } from "../utils/parsing";
import { isWithinWeek, isWithinMonth, isWithinYear, getShortDate } from "../utils/dateUtils";

// Comment out the CategoryFilters component
/*
const CategoryFilters = ({ activeFilter, onFilterClick }) => {
  const containerRef = useRef(null);
  const filters = ["all", "work", "health", "heart", "mind"];

  const createFilterSet = (position = "center") => (
    <div className={`filter-button-set ${position}`}>
      {filters.map((filter) => (
        <button
          key={`${position}-${filter}`}
          data-filter={filter}
          className={`filter-button ${activeFilter === filter ? "active" : ""}`}
          onClick={() => onFilterClick(filter)}
        >
          {filter}
        </button>
      ))}
    </div>
  );

  const centerActiveButton = (filter) => {
    const container = containerRef.current;
    if (!container) return;

    const containerWidth = 332;
    const buttonWidth = 64;
    const gap = 2;
    const totalWidth = filters.length * (buttonWidth + gap) - gap;

    const activeIndex = filters.indexOf(filter);
    const centerPosition = containerWidth / 2;
    const buttonCenter = buttonWidth / 2;
    const baseOffset = centerPosition - (activeIndex * (buttonWidth + gap) + buttonCenter);

    // Determine which set of buttons to show based on the offset
    let finalOffset = baseOffset;
    if (Math.abs(baseOffset) > totalWidth / 2) {
      finalOffset = baseOffset + (baseOffset > 0 ? -totalWidth : totalWidth);
    }

    container.style.transform = `translateX(${finalOffset}px)`;
  };

  useEffect(() => {
    centerActiveButton(activeFilter);
  }, [activeFilter]);

  return (
    <div className="category-filters">
      <div className="filter-button-container" ref={containerRef}>
        {createFilterSet("left")}
        {createFilterSet("center")}
        {createFilterSet("right")}
      </div>
    </div>
  );
};
*/

// Move this outside the component if it doesn't need component state
const filterNotes = (notes, { timeFilter, categoryFilter, dayFilter }) => {
  return notes
    .filter((note) => {
      // Time filtering
      switch (timeFilter) {
        case "week":
          return isWithinWeek(note.lastEdited);
        case "month":
          return isWithinMonth(note.lastEdited);
        case "year":
          return isWithinYear(note.lastEdited);
        default:
          return true;
      }
    })
    .filter((note) => {
      // Category filtering
      if (categoryFilter === "all") return true;
      return note.category === categoryFilter;
    })
    .filter((note) => {
      // Day filtering
      if (dayFilter === "ALL") return true;
      const date = new Date(note.lastEdited);
      
      switch (timeFilter) {
        case "week":
          return date.toLocaleString("en-US", { weekday: "short" }).toUpperCase() === dayFilter;
        case "month":
          const weekNumber = Math.ceil(date.getDate() / 7);
          return `WK${weekNumber}` === dayFilter;
        case "year":
          return date.toLocaleString("en-US", { month: "short" }).toUpperCase() === dayFilter;
        default:
          return true;
      }
    });
};

const EmotionGraph = ({
  notes,
  setSelectedNoteId: setSelectedNoteIdAndNavigate,
  setIsEmotionGraphOpen,
}) => {
  const svgRef = useRef();
  const containerRef = useRef();
  const graphRef = useRef();
  const previewRef = useRef(null);
  const activeNodeRef = useRef(null);

  const [filteredNotes, setFilteredNotes] = useState(notes);

  const TIME_FILTERS = ["week", "month", "year", "all"]; // todo: ugh if we were doing typescript we could make this an enum
  const CATEGORY_FILTERS = ["all", "work", "health", "heart", "mind"];

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [preview, setPreview] = useState({
    visible: false,
    content: null,
    x: 0,
    y: 0,
    positionClasses: "",
  });
  const isMobile = useIsMobile();
  const [hoveredNodeId, setHoveredNodeId] = useState(null);
  const [isMouseBetweenNodeAndPreview, setIsMouseBetweenNodeAndPreview] = useState(false);
  const [filters, setFilters] = useState({
    timeFilter: TIME_FILTERS[0],
    categoryFilter: CATEGORY_FILTERS[0],
    dayFilter: "ALL",
  });
  const timeFiltersRef = useRef(null);
  const categoryFiltersRef = useRef(null);

  // Add the emotion categories mapping
  const EMOTION_CATEGORIES = {
    Positive: [
      "Satisfaction",
      "Relief",
      "Contentment",
      "Joy",
      "Pride",
      "Admiration",
      "Gratitude",
      "Calmness",
      "Determination",
      "Amusement",
      "Triumph",
      "Enthusiasm",
      "Excitement",
      "Surprise (positive)",
      "Ecstasy",
    ],
    "Positive Social": ["Romance", "Love", "Adoration", "Sympathy"],
    Negative: [
      "Tiredness",
      "Boredom",
      "Sadness",
      "Fear",
      "Annoyance",
      "Distress",
      "Contempt",
      "Doubt",
      "Disappointment",
      "Disapproval",
      "Anxiety",
      "Anger",
      "Disgust",
      "Pain",
      "Horror",
      "Surprise (negative)",
      "Envy",
    ],
    "Negative Social": ["Shame", "Guilt", "Empathic Pain", "Awkwardness", "Embarrassment"],
    Cognitive: [
      "Interest",
      "Concentration",
      "Contemplation",
      "Awe",
      "Realization",
      "Confusion",
      "Sarcasm",
    ],
    "Complex n Other": ["Nostalgia", "Aesthetic Appreciation", "Entrancement", "Craving", "Desire"],
  };

  // Helper function to get category for an emotion
  const getEmotionCategory = (emotion) => {
    const category = Object.entries(EMOTION_CATEGORIES).find(([category, emotions]) =>
      emotions.includes(emotion)
    )?.[0];

    if (!category) {
      console.warn(`No category found for emotion: ${emotion}`);
    }
    return category || "Other";
  };

  // Add these function definitions at the component level
  const handlePreviewMouseEnter = () => {
    setPreview((prev) => ({ ...prev, visible: true }));
  };

  const handlePreviewMouseLeave = () => {
    setHoveredNodeId(null);
    setPreview((prev) => ({ ...prev, visible: false }));
  };

  function createVisualization(svg, notes, dimensions) {
    // Move all the D3 visualization logic here
    // Use the passed svg, notes, and dimensions instead of accessing them from closure
    const { width, height } = dimensions;
    const centerX = width / 2; // width of side filters
    const centerY = height / 2;
    // const radius = Math.min(width, height) / 3.75;
    const radiusX = width / 5;
    const radiusY = height / 3.5;

    svg.attr("width", width).attr("height", height);

    // Create nodes for each emotion in each note
    const nodes = notes.flatMap((note) =>
      note.emotions.map((emotion) => ({
        id: `${note.id}-${emotion}`,
        emotion: emotion,
        noteId: note.id,
      }))
    );

    // Create links between emotions in the same note
    const links = notes.flatMap((note) => {
      const noteEmotions = note.emotions;
      return noteEmotions.flatMap((emotion, index) =>
        noteEmotions.slice(index + 1).map((target) => ({
          source: `${note.id}-${emotion}`,
          target: `${note.id}-${target}`,
          noteId: note.id,
        }))
      );
    });

    // Create a custom color mapping for emotions
    const emotionColors = {
      Admiration: "rgba(224, 164, 0, 0.22)",
      Adoration: "rgba(247, 11, 97, 0.16)",
      "Aesthetic Appreciation": "rgba(255, 20, 177, 0.16)",
      Amusement: "rgba(255, 102, 0, 0.2)",
      Anger: "rgba(249, 46, 0, 0.18)",
      Annoyance: "rgba(244, 137, 61, 0.22)",
      Anxiety: "rgba(8, 0, 249, 0.12)",
      Awe: "rgba(0, 26, 255, 0.14)",
      Awkwardness: "rgba(50, 233, 175, 0.2)",
      Boredom: "rgba(53, 55, 59, 0.14)",
      Calmness: "rgba(0, 87, 249, 0.16)",
      Concentration: "rgba(0, 128, 255, 0.18)",
      Confusion: "rgba(125, 0, 174, 0.14)",
      Contemplation: "rgba(0, 202, 213, 0.18)",
      Contempt: "rgba(194, 56, 35, 0.16)",
      Contentment: "rgba(28, 234, 186, 0.18)",
      Craving: "rgba(255, 0, 47, 0.14)",
      Desire: "rgba(255, 0, 111, 0.14)",
      Determination: "rgba(0, 178, 80, 0.18)",
      Disappointment: "rgba(0, 45, 178, 0.12)",
      Disapproval: "rgba(186, 85, 69, 0.2)",
      Disgust: "rgba(202, 233, 0, 0.24)",
      Distress: "rgba(249, 91, 0, 0.18)",
      Doubt: "rgba(95, 249, 0, 0.16)",
      Ecstasy: "rgba(255, 0, 161, 0.16)",
      Embarrassment: "rgba(249, 236, 0, 0.32)",
      "Empathic Pain": "#8000001A",
      Enthusiasm: "rgba(249, 199, 0, 0.28)",
      Entrancement: "rgba(220, 20, 255, 0.16)",
      Envy: "rgba(9, 178, 0, 0.18)",
      Excitement: "rgba(249, 216, 0, 0.28)",
      Fear: "rgba(174, 135, 76, 0.18)",
      Gratitude: "rgba(255, 149, 0, 0.22)",
      Guilt: "rgba(148, 76, 174, 0.18)",
      Horror: "rgba(174, 76, 76, 0.18)",
      Interest: "rgba(50, 233, 209, 0.18)",
      Joy: "rgba(255, 170, 0, 0.22)",
      Love: "rgba(248, 10, 152, 0.16)",
      Nostalgia: "rgba(65, 84, 255, 0.14)",
      Pain: "rgba(203, 1, 14, 0.14)",
      Pride: "rgba(173, 20, 255, 0.18)",
      Realization: "rgba(0, 212, 228, 0.2)",
      Relief: "rgba(4, 150, 255, 0.2)",
      Romance: "rgba(255, 20, 177, 0.18)",
      Sadness: "rgba(0, 83, 210, 0.14)",
      Sarcasm: "rgba(0, 237, 16, 0.18)",
      Satisfaction: "rgba(4, 196, 255, 0.2)",
      Shame: "rgba(166, 108, 0, 0.18)",
      "Surprise (negative)": "rgba(249, 162, 0, 0.22)",
      "Surprise (positive)": "rgba(108, 249, 0, 0.22)",
      Sympathy: "rgba(212, 20, 255, 0.16)",
      Tiredness: "rgba(23, 0, 138, 0.1)",
      Triumph: "rgba(209, 160, 0, 0.22)",
    };

    // Create a color scale for emotions
    const uniqueEmotions = Array.from(new Set(nodes.map((n) => n.emotion)));
    const colorScale = d3
      .scaleOrdinal()
      .domain(uniqueEmotions)
      .range(uniqueEmotions.map((emotion) => emotionColors[emotion] || "#999999")); // Use gray for unknown emotions

    // First, let's organize nodes by emotion
    const emotionGroups = {};
    nodes.forEach((node) => {
      if (!emotionGroups[node.emotion]) {
        emotionGroups[node.emotion] = [];
      }
      emotionGroups[node.emotion].push(node);
    });

    // Calculate cluster centers with category-based positioning
    const emotionPositions = {};
    const categoryPositions = {};

    // First, position the categories
    Object.keys(EMOTION_CATEGORIES).forEach((category, i) => {
      const goldenRatio = (1 + Math.sqrt(5)) / 2;
      const angle = (i * 2 * Math.PI) / Object.keys(EMOTION_CATEGORIES).length;
      const radiusXJitter = radiusX + (Math.random() - 0.5);
      const radiusYJitter = radiusY + (Math.random() - 0.5);
      const angleJitter = angle + (Math.random() - 0.5) * 0.2;

      categoryPositions[category] = {
        x: centerX + Math.cos(angleJitter) * radiusXJitter,
        y: centerY + Math.sin(angleJitter) * radiusYJitter,
      };
    });

    // Log unique emotions that we're trying to position
    // console.log("Unique emotions:", uniqueEmotions);

    // Then, position emotions within their category areas
    uniqueEmotions.forEach((emotion) => {
      if (!emotion) {
        console.warn("Undefined emotion found in uniqueEmotions");
        return;
      }

      const category = getEmotionCategory(emotion);
      const basePosition = categoryPositions[category];

      if (!basePosition) {
        console.warn(`No base position found for category: ${category}, emotion: ${emotion}`);
        // Provide a fallback position at the center
        emotionPositions[emotion] = {
          x: centerX + (Math.random() - 0.5) * 100,
          y: centerY + (Math.random() - 0.5) * 100,
        };
        return;
      }

      // This controls the initial spread of nodes within their emotion group

      const offsetAngle = Math.random() * 2 * Math.PI;

      emotionPositions[emotion] = {
        x: basePosition.x + Math.cos(offsetAngle) * radiusX * Math.random(),
        y: basePosition.y + Math.sin(offsetAngle) * radiusY * Math.random(),
      };
    });

    // 2. Force simulation controls ongoing node positions
    const simulation = d3
      .forceSimulation(nodes)
      .force(
        "link",
        d3
          .forceLink(links)
          .id((d) => d.id)
          .strength(0.03)
      )
      .force("charge", d3.forceManyBody().strength(-30))
      .force("collision", d3.forceCollide().radius(10))
      .force(
        "x",
        d3
          .forceX()
          .strength(0.9)
          .x((d) => {
            const baseX = emotionPositions[d.emotion].x;
            return baseX + (Math.random() - 0.5) * 100;
          })
      )
      .force(
        "y",
        d3
          .forceY()
          .strength(0.9)
          .y((d) => {
            const baseY = emotionPositions[d.emotion].y;
            return baseY + (Math.random() - 0.5) * 25;
          })
      )
      .force(
        "radial",
        d3
          .forceRadial(
            radiusX * 1.2, // Target radius - increase this to make the circle bigger
            centerX,
            centerY
          )
          .strength(0.05)
      ); // Strength of the circular pull - increase for more perfect circle

    // Create links
    const link = svg
      .append("g")
      .selectAll("line")
      .data(links)
      .enter()
      .append("line")
      .attr("stroke", "#EAEAF2") // Light grey color
      .attr("stroke-opacity", 0.6)
      .attr("stroke-width", 1)
      .attr("class", "link")
      .attr("data-note-id", (d) => d.noteId) // Store noteId as a data attribute
      .on("mouseover", handleMouseOverLink) // Add mouseover event
      .on("mouseout", handleMouseOutLink); // Add mouseout event

    // Create nodes
    const node = svg
      .append("g")
      .selectAll("circle")
      .data(nodes)
      .enter()
      .append("circle")
      .attr("r", 5)
      .attr("fill", (d) => colorScale(d.emotion))
      .attr("class", (d) => `node emotion-${d.emotion.replace(/[\s()]/g, "-")}`)
      .attr("data-note-id", (d) => d.noteId)
      .attr("data-emotion", (d) => d.emotion)
      .call(d3.drag().on("start", dragstarted).on("drag", dragged).on("end", dragended))
      .on("mouseover", function (event, d) {
        setHoveredNodeId(d.noteId);
        const note = notes.find((n) => n.id === d.noteId);
        if (!note) return;

        // Highlight all links for this note
        handleMouseOverLink(event, d);

        const rect = graphRef.current.getBoundingClientRect();
        const x = event.pageX - rect.left;
        const y = event.pageY - rect.top;

        // Preview dimensions
        const previewWidth = 280;
        const previewHeight = 150;
        const padding = 20;
        const topOffset = 200;

        // Available space calculations
        const spaceRight = window.innerWidth - (event.pageX + previewWidth + padding);
        const spaceLeft = event.pageX - previewWidth - padding;
        const spaceTop = event.pageY - previewHeight - padding - topOffset;
        const spaceBottom = window.innerHeight - (event.pageY + previewHeight + padding);

        // Determine position classes
        let positionClass = "";

        // First determine left/right positioning
        if (spaceRight < 0 && spaceLeft > 0) {
          // Not enough space on right, use left side
          positionClass = "left-side";
        }

        // Then determine top/bottom positioning if needed
        if (spaceBottom < 0 && spaceTop > 0) {
          // Not enough space on bottom, use top
          positionClass = positionClass ? `top-${positionClass}` : "top-side";
        } else if (spaceTop < 0 && spaceBottom > 0) {
          // Not enough space on top, use bottom
          positionClass = positionClass ? `bottom-${positionClass}` : "bottom-side";
        }

        setPreview({
          visible: true,
          content: {
            noteId: d.noteId,
            emotion: d.emotion,
            noteTitle: note.title || "Untitled",
            noteContent: note.content,
            emotions: note.emotions,
            lastEdited: note.lastEdited,
          },
          x,
          y,
          positionClasses: positionClass,
        });

        // Highlight the node
        d3.select(event.target).attr("r", 7);
      })
      .on("mouseout", function (event, d) {
        // Reset link styles
        handleMouseOutLink(event, d);

        // Hide preview when mouse leaves node
        // setPreview((prev) => ({ ...prev, visible: false }));
        d3.select(this).attr("r", 5);
      })
      .on("click", (event, d) => {
        setSelectedNoteIdAndNavigate(d.noteId);
      });

    // Update the label creation with emotion-based highlighting
    const labelData = uniqueEmotions.map((emotion) => ({
      emotion: emotion,
      x: emotionPositions[emotion].x,
      y: emotionPositions[emotion].y,
      color: EMOTION_COLORS[emotion]?.text || "#999999",
    }));

    const label = svg
      .append("g")
      .selectAll("text")
      .data(labelData)
      .enter()
      .append("text")
      .text((d) => d.emotion.toUpperCase()) // Convert text to uppercase
      .attr("class", (d) => `emotion-label-${d.emotion.replace(/[\s()]/g, "-")}`)
      .attr("font-size", 10)
      .attr("text-anchor", "end") // Center the text horizontally
      .attr("dx", 10)
      .attr("dy", -10)
      .attr("fill", (d) => d.color)
      .style("cursor", "pointer")
      .style("font-family", "instrument-sans-variable, sans-serif")
      .style("font-variation-settings", '"wght" 500')
      .on("mouseover", handleMouseOverEmotion)
      .on("mouseout", handleMouseOutEmotion);

    // Update the simulation.on("tick") to include the new label positioning:
    simulation.on("tick", () => {
      // Helper function to get constrained position
      const getConstrainedX = (d) => {
        const baseX = emotionPositions[d.emotion].x;
        const dx = d.x - baseX;
        const maxDistance = 30;
        return baseX + Math.max(Math.min(dx, maxDistance), -maxDistance);
      };

      const getConstrainedY = (d) => {
        const baseY = emotionPositions[d.emotion].y;
        const dy = d.y - baseY;
        const maxDistance = 30;
        return baseY + Math.max(Math.min(dy, maxDistance), -maxDistance);
      };

      // Update link positions using the same constraints as nodes
      link
        .attr("x1", (d) => getConstrainedX(d.source))
        .attr("y1", (d) => getConstrainedY(d.source))
        .attr("x2", (d) => getConstrainedX(d.target))
        .attr("y2", (d) => getConstrainedY(d.target));

      // Update node positions (using same constraint functions)
      node.attr("cx", getConstrainedX).attr("cy", getConstrainedY);

      // Keep existing label positioning logic
      label
        .attr("x", (d) => {
          const nodes = d3.selectAll(`circle[data-emotion="${d.emotion}"]`);
          const xPositions = [];
          nodes.each(function () {
            xPositions.push(+d3.select(this).attr("cx"));
          });
          return d3.mean(xPositions) || emotionPositions[d.emotion].x;
        })
        .attr("y", (d) => {
          const nodes = d3.selectAll(`circle[data-emotion="${d.emotion}"]`);
          const yPositions = [];
          nodes.each(function () {
            yPositions.push(+d3.select(this).attr("cy"));
          });
          return d3.mean(yPositions) || emotionPositions[d.emotion].y;
        });
    });

    // Drag functions
    function dragstarted(event, d) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(event, d) {
      d.fx = event.x;
      d.fy = event.y;
    }

    function dragended(event, d) {
      if (!event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    }

    // Event handler for mouseover on links
    function handleMouseOverLink(event, d) {
      // Highlight all links with the same noteId
      svg
        .selectAll(`.link[data-note-id='${d.noteId}']`)
        .attr("stroke", "#bfbfbf") // Change to a highlight color (e.g., orange-red)
        .attr("stroke-width", 2); // Increase the stroke width
    }

    // Event handler for mouseout on links
    function handleMouseOutLink(event, d) {
      // Reset the styling of links with the same noteId
      svg
        .selectAll(`.link[data-note-id='${d.noteId}']`)
        .attr("stroke", "#EAEAF2") // Original light grey color
        .attr("stroke-width", 1); // Original stroke width
    }

    // Event handler for mouseout on nodes
    function handleMouseOutNode(event, d) {
      // Reset only the radius
      d3.select(event.target).attr("r", 5);
    }

    function handleClickNode(event, d) {
      setSelectedNoteIdAndNavigate(d.noteId);
    }

    // Add new event handlers for emotion highlighting
    function handleMouseOverEmotion(event, d) {
      const emotion = d.emotion || d.data?.emotion;
      if (!emotion) return;

      // Dim all nodes and labels
      node.attr("opacity", 0.2);
      label.attr("opacity", 0.2);

      // Highlight nodes of the same emotion
      svg.selectAll(`circle[data-emotion="${emotion}"]`).attr("opacity", 1);

      // Highlight the corresponding label
      svg.select(`.emotion-label-${emotion.replace(/[\s()]/g, "-")}`).attr("opacity", 1);

      // Highlight related links
      link.attr("opacity", 0.1); // Dim all links
      link
        .filter(
          (l) =>
            nodes.find((n) => n.id === l.source.id)?.emotion === emotion ||
            nodes.find((n) => n.id === l.target.id)?.emotion === emotion
        )
        .attr("opacity", 0.6);
    }

    function handleMouseOutEmotion() {
      // Reset everything back to full opacity
      node.attr("opacity", 1);
      label.attr("opacity", 1);
      link.attr("opacity", 0.6);
    }
  }

  useEffect(() => {
    const updateDimensions = () => {
      if (graphRef.current) {
        setDimensions({
          width: graphRef.current.clientWidth,
          height: graphRef.current.clientHeight,
        });
      }
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);

    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    // console.log("filteredNotes", filteredNotes.length);
    if (!filteredNotes || dimensions.width === 0 || dimensions.height === 0) return;

    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove(); // Clear previous content

    createVisualization(svg, filteredNotes, dimensions);
  }, [filteredNotes, dimensions]);

  // Single useEffect to handle all filtering
  useEffect(() => {
    const filtered = filterNotes(notes, filters);
    setFilteredNotes(filtered);
  }, [notes, filters]);

  // Define centerActiveButton within the component scope
  const centerActiveButton = (filter, type) => {
    const container = type === "time" ? timeFiltersRef.current : categoryFiltersRef.current;
    if (!container) return;

    const containerWidth = 332;
    const buttonWidth = 64;
    const gap = 2;

    const buttons = type === "time" ? TIME_FILTERS : CATEGORY_FILTERS;

    const activeIndex = buttons.indexOf(filter);
    const totalWidth = buttons.length * (buttonWidth + gap) - gap;

    // Calculate base centering offset
    const centerPosition = containerWidth / 3.3;
    const buttonCenter = buttonWidth / 2;
    const baseOffset = centerPosition - (activeIndex * (buttonWidth + gap) + buttonCenter);

    if (type === "time") {
      // Simple centering for time filters
      container.style.transform = `translateX(${baseOffset}px)`;
    } else {
      // Handle wrapping for category filters
      let finalOffset = baseOffset;
      if (Math.abs(baseOffset) > totalWidth / 2) {
        finalOffset = baseOffset + (baseOffset > 0 ? -totalWidth : totalWidth);
      }
      container.style.transform = `translateX(${finalOffset}px)`;
    }
  };

  const handleTimeFilterClick = (filter) => {
    setFilters((prev) => ({ 
      ...prev, 
      timeFilter: filter,
      dayFilter: "ALL"  // Reset dayFilter to "ALL" when timeFilter changes
    }));
  };

  const handleCategoryFilterClick = (filter) => {
    setFilters((prev) => ({ ...prev, categoryFilter: filter }));
  };

  const handleDayFilterClick = (day) => {
    setFilters((prev) => ({ ...prev, dayFilter: day }));
  };

  useEffect(() => {
    setIsEmotionGraphOpen(true);
    return () => setIsEmotionGraphOpen(false);
  }, []);

  // Add these helper functions
  const getDateFilters = (timeFilter) => {
    switch (timeFilter) {
      case "week":
        return ["ALL", "MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
      case "month":
        return ["ALL", "WK1", "WK2", "WK3", "WK4", "WK5"];
      case "year":
        return ["ALL", "JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
      default:
        return [];
    }
  };

  return (
    <div className="another-container">
      <div className="filter-bar-container">
        <div className="filter-bar">
          <div className="time-filters" data-active={TIME_FILTERS.indexOf(filters.timeFilter)}>
            <div className="filter-button-container">
              {TIME_FILTERS.map((filter) => (
                <button
                  key={filter}
                  data-filter={filter}
                  className={`filter-button ${filters.timeFilter === filter ? "active" : ""}`}
                  onClick={() => handleTimeFilterClick(filter)}
                >
                  {filter}
                </button>
              ))}
            </div>
          </div>
          {/* Comment out the category filters */}
          {/*<div className="category-filters">
            <CategoryFilters
              activeFilter={filters.categoryFilter}
              onFilterClick={(filter) =>
                setFilters((prev) => ({ ...prev, categoryFilter: filter }))
              }
            />            // todo: add category filters
          </div>*/}
        </div>
      </div>
      <div className="graph-content">
        <div className="side-filters">
          {getDateFilters(filters.timeFilter).map((filter) => (
            <div
              key={filter}
              className={`day-filter ${filters.dayFilter === filter ? "active" : ""}`}
              onClick={() => handleDayFilterClick(filter)}
            >
              {filter}
            </div>
          ))}
        </div>
        <div ref={graphRef} className="emotion-graph">
          <svg ref={svgRef}></svg>
          {preview.visible && (
            <div
              ref={previewRef}
              className={`emotion-preview ${preview.positionClasses || ""}`}
              style={{
                left: `${
                  preview.x + (preview.positionClasses?.includes("right-edge") ? -20 : 0)
                }px`,
                top: `${preview.y}px`,
              }}
              onMouseEnter={handlePreviewMouseEnter}
              onMouseLeave={handlePreviewMouseLeave}
              onClick={() => setSelectedNoteIdAndNavigate(preview.content.noteId)}
            >
              {preview.content && (
                <>
                  <div className="emotion-preview-title">{preview.content.noteTitle}</div>
                  <div className="emotion-preview-content">
                    {cleanWithNewLines(preview.content.noteContent)}
                  </div>
                  <div className="note-metadata-container">
                    {preview.content.emotions && preview.content.emotions.length > 0 && (
                      <div className="note-emotions">
                        {preview.content.emotions
                          .sort((a, b) => b.score - a.score)
                          .map((emotion, index) => (
                            <span
                              key={index}
                              className="note-editor-emotion"
                              style={{
                                color: EMOTION_COLORS[emotion]?.text || "#000000",
                                backgroundColor: EMOTION_COLORS[emotion]?.background || "#FFFFFF",
                                minWidth: index === 0 ? "auto" : "18px",
                                width: index === 0 ? "auto" : "18px",
                                height: "18px",
                                padding: index === 0 ? "0.32rem 0.65rem 0.125rem 0.65rem" : "6px",
                                borderRadius: "9999px",
                              }}
                            >
                              {index === 0 ? emotion : ""}
                            </span>
                          ))}
                      </div>
                    )}
                    <p className="note-date">{getShortDate(preview.content.lastEdited)}</p>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmotionGraph;
