import React from "react";
import "../styles/MobileBar.css";
import { BookHeart, Waypoints } from "lucide-react";
import { useNavigate, useLocation } from "react-router-dom";

const MobileBar = ({ addNote }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isHomePath = pathname === "/";

  return (
    <div className="bar-container-wrapper">
      <div
        className="bar-container"
        // style={{ visibility: isHomePath ? "visible" : "hidden" }}
        onClick={addNote}
      >
        <div className="bar-text">How do you feel today?</div>
      </div>
      {isHomePath ? (
        <button onClick={() => navigate("/graph")} className="icon-button-mobile">
          <Waypoints size={16} color="#383868" style={{ opacity: 0.55 }} />
        </button>
      ) : (
        <button onClick={() => navigate("/")} className="icon-button-mobile">
          <BookHeart size={16} color="#383868" style={{ opacity: 0.55 }} />
        </button>
      )}
    </div>
  );
};

export default MobileBar;
