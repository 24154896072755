import React, { useState, useEffect } from "react";
import { MoreHorizontal, Heart, X } from "lucide-react";
import firebase from "firebase/compat/app";
import "../styles/Modal.css";
import axios from "axios";
const FeedbackModal = ({ isOpen, onClose, user }) => {
  const [feedback, setFeedback] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");

  const handleSubmit = async () => {
    if (!feedback.trim()) return;

    setIsSubmitting(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL || "http://localhost:3001"}/feedback`,
        { userId: user.uid, content: feedback.trim() }
      );

      if (response.status === 200) {
        setMessage("Thank you for your feedback! ❤️");
        setFeedback("");
        setTimeout(() => {
          onClose();
          setMessage("");
        }, 2000);
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
      setMessage("Failed to submit feedback. Please try again.");
    }
    setIsSubmitting(false);
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h3>Send feedback</h3>
          <button onClick={onClose} className="icon-button">
            <X size={16} />
          </button>
        </div>
        <textarea
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          placeholder="Tell us what you think..."
          className="feedback-textarea"
          maxLength={1000}
        />
        {message && <div className="feedback-message">{message}</div>}
        <button
          onClick={handleSubmit}
          className="submit-button"
          disabled={isSubmitting || !feedback.trim()}
        >
          {isSubmitting ? "Sending..." : "Submit"}
        </button>
      </div>
    </div>
  );
};

function SignOutMenu({ onSignOut, user }) {
  const [showSignOutMenu, setShowSignOutMenu] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showSignOutMenu && !event.target.closest(".menu-button-container")) {
        setShowSignOutMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showSignOutMenu]);

  return (
    <>
      <div className="menu-button-container">
        <button onClick={() => setShowSignOutMenu(!showSignOutMenu)} className="icon-button">
          <MoreHorizontal size={16} />
          <span className="tooltip tooltip-bottom-left">Menu</span>
        </button>
        {showSignOutMenu && (
          <div className="menu-popup">
            <button onClick={onSignOut} className="menu-item">
              Sign out
            </button>
            <button
              onClick={() => {
                setShowFeedbackModal(true);
                setShowSignOutMenu(false);
              }}
              className="menu-item"
            >
              Send us feedback &lt;3
            </button>
            <button
              onClick={() => window.open("https://discord.gg/bvWwbC5dcH", "_blank")}
              className="menu-item"
            >
              Join our Discord
            </button>
            <button
              onClick={() =>
                window.open(
                  "https://knowing-radium-977.notion.site/terms-and-privacy?pvs=4",
                  "_blank"
                )
              }
              className="menu-item"
            >
              Privacy Policy & TOS
            </button>
          </div>
        )}
      </div>
      <FeedbackModal
        isOpen={showFeedbackModal}
        onClose={() => setShowFeedbackModal(false)}
        user={user}
      />
    </>
  );
}

export default SignOutMenu;
