export const EMOTION_COLORS = {
    Admiration: { text: '#C26E00', background: 'rgba(224, 164, 0, 0.22)' }, // Gold
    Adoration: { text: '#CF0C53', background: 'rgba(247, 11, 97, 0.16)' }, // Hot Pink
    'Aesthetic Appreciation': { text: '#D51D98', background: 'rgba(255, 20, 177, 0.16)' }, // Plum
    Amusement: { text: '#DE4C03', background: 'rgba(255, 102, 0, 0.2)' }, // Orange
    Anger: { text: '#BD2727', background: 'rgba(249, 46, 0, 0.18)' }, // Red
    Annoyance: { text: '#BF5538', background: 'rgba(244, 137, 61, 0.22)' }, // Saddle Brown
    Anxiety: { text: '#4D4AB9', background: 'rgba(8, 0, 249, 0.12)' }, // Indigo
    Awe: { text: '#0F26E9', background: 'rgba(0, 26, 255, 0.14)' }, // Dodger Blue
    Awkwardness: { text: '#077363', background: 'rgba(50, 233, 175, 0.2)' }, // Pink
    Boredom: { text: '#515167', background: 'rgba(53, 55, 59, 0.14)' }, // Gray
    Calmness: { text: '#2656B1', background: 'rgba(0, 87, 249, 0.16)' }, // Lavender
    Concentration: { text: '#0666C6', background: 'rgba(0, 128, 255, 0.18)' }, // Navy
    Confusion: { text: '#9932CC', background: 'rgba(125, 0, 174, 0.14)' }, // Dark Orchid
    Contemplation: { text: '#0680C6', background: 'rgba(0, 202, 213, 0.18)' }, // Dark Slate Gray
    Contempt: { text: '#AD100D', background: 'rgba(194, 56, 35, 0.16)' }, // Dark Red
    Contentment: { text: '#168681', background: 'rgba(28, 234, 186, 0.18)' }, // Pale Green
    Craving: { text: '#A33C67', background: 'rgba(255, 0, 47, 0.14)' }, // Saddle Brown
    Desire: { text: '#C50455', background: 'rgba(255, 0, 111, 0.14)' }, // Deep Pink
    Determination: { text: '#038455', background: 'rgba(0, 178, 80, 0.18)' }, // Dark Green
    Disappointment: { text: '#5B58A8', background: 'rgba(0, 45, 178, 0.12)' }, // Steel Blue
    Disapproval: { text: '#9D403F', background: 'rgba(186, 85, 69, 0.2)' }, // Indian Red
    Disgust: { text: '#4A8212', background: 'rgba(202, 233, 0, 0.24)' }, // Forest Green
    Distress: { text: '#B13F19', background: 'rgba(249, 91, 0, 0.18)' }, // Fire Brick
    Doubt: { text: '#308327', background: 'rgba(95, 249, 0, 0.16)' }, // Light Slate Gray
    Ecstasy: { text: '#D00091', background: 'rgba(255, 0, 161, 0.16)' }, // Magenta
    Embarrassment: { text: '#8C7E01', background: 'rgba(249, 236, 0, 0.32)' }, // Tomato
    'Empathic Pain': { text: '#800000', background: '#8000001A' }, // Maroon
    Enthusiasm: { text: '#C56C00', background: 'rgba(249, 199, 0, 0.28)' }, // Gold
    Entrancement: { text: '#C305AD', background: 'rgba(220, 20, 255, 0.16)' }, // Dark Violet
    Envy: { text: '#1B6A00', background: 'rgba(9, 178, 0, 0.18)' }, // Dark Green
    Excitement: { text: '#AB6C00', background: 'rgba(249, 216, 0, 0.28)' }, // Orange Red
    Fear: { text: '#8B5039', background: 'rgba(174, 135, 76, 0.18)' }, // Black
    Gratitude: { text: '#BA643C', background: 'rgba(255, 149, 0, 0.22)' }, // Goldenrod
    Guilt: { text: '#5F1297', background: 'rgba(148, 76, 174, 0.18)' }, // Dark Red
    Horror: { text: '#8B3939', background: 'rgba(174, 76, 76, 0.18)' }, // Dark Magenta
    Interest: { text: '#2D8A8F', background: 'rgba(50, 233, 209, 0.18)' }, // Royal Blue
    Joy: { text: '#BA643C', background: 'rgba(255, 170, 0, 0.22)' }, // Yellow
    Love: { text: '#B53674', background: 'rgba(248, 10, 152, 0.16)' }, // Deep Pink
    Nostalgia: { text: '#3C4BD5', background: 'rgba(65, 84, 255, 0.14)' }, // Light Steel Blue
    Pain: { text: '#C94261', background: 'rgba(203, 1, 14, 0.14)' }, // Crimson
    Pride: { text: '#7A05C3', background: 'rgba(173, 20, 255, 0.18)' }, // Purple
    Realization: { text: '#028C91', background: 'rgba(0, 212, 228, 0.2)' }, // Dark Turquoise
    Relief: { text: '#007EC2', background: 'rgba(4, 150, 255, 0.2)' }, // Aquamarine
    Romance: { text: '#D20A99', background: 'rgba(255, 20, 177, 0.18)' }, // Hot Pink
    Sadness: { text: '#4262B3', background: 'rgba(0, 83, 210, 0.14)' }, // Steel Blue
    Sarcasm: { text: '#00820D', background: 'rgba(0, 237, 16, 0.18)' }, // Tomato
    Satisfaction: { text: '#177BB9', background: 'rgba(4, 196, 255, 0.2)' }, // Light Sea Green
    Shame: { text: '#895429', background: 'rgba(166, 108, 0, 0.18)' }, // Saddle Brown
    'Surprise (negative)': { text: '#A45010', background: 'rgba(249, 162, 0, 0.22)' }, // Orange Red
    'Surprise (positive)': { text: '#168200', background: 'rgba(108, 249, 0, 0.22)' }, // Spring Green
    Sympathy: { text: '#A905C3', background: 'rgba(212, 20, 255, 0.16)' }, // Plum
    Tiredness: { text: '#5B57A3', background: 'rgba(23, 0, 138, 0.1)' }, // Slate Gray
    Triumph: { text: '#B17900', background: 'rgba(209, 160, 0, 0.22)' }, // Gold
};