import axios from "axios";

const API_URL = `${
  process.env.NODE_ENV === "production" ? process.env.REACT_APP_API_URL : "http://localhost:3001"
}/analytics`;

export const trackUserAuth = async (user, eventName) => {
  try {
    await axios.post(`${API_URL}/track-auth`, {
      userId: user.uid,
      email: user.email,
      name: user.displayName || user.email.split("@")[0],
      eventName,
    });
  } catch (error) {
    console.error("Failed to track auth event:", error);
  }
};

export const trackEvent = async (event, properties) => {
  try {
    await axios.post(`${API_URL}/track`, { event, properties });
  } catch (error) {
    console.error("Failed to track event:", error);
  }
};
