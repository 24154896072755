export const removeHTMLTagsAndReflections = (content) => {
  // First remove reflection tags and content inside them
  const withoutReflections = content.replace(
    /<reflection class="reflection-node">.*?<\/reflection>/gs,
    ""
  );
  // Then remove any remaining HTML tags
  return withoutReflections.replace(/<[^>]*>/g, "");
};

export const cleanWithNewLines = (content) => {
  return removeHTMLTagsAndReflections(content.replace(/<p>/g, "").replace(/<\/p>/g, "\n"));
};

export const extractReflections = (content) => {
  const reflections = [];
  const reflectionRegex = /<reflection class="reflection-node">(.*?)<\/reflection>/gs;

  // Extract all reflections
  let match;
  while ((match = reflectionRegex.exec(content)) !== null) {
    reflections.push(match[1].trim());
  }

  // Remove reflection tags and their content
  const cleanedText = content
    .replace(reflectionRegex, "")
    // Remove any remaining HTML tags
    .replace(/<[^>]*>/g, "")
    .trim();

  return {
    cleanedText,
    reflections,
  };
};
